import { SEO_PAGE_CATEGORY_ENUM } from "@/constants";

/**
 * takes an episode object
 * and formats a path to that episode
 * /shows/{showId}/{seriesName}/season-{foo}/episode-{bar}
 * @returns
 */
export default function (episode, extraSlugs = []) {
  if (!episode) return null;
  if (
    !episode.tvShowReference ||
    (episode.tvShowReference &&
      Object.values(episode.tvShowReference).length === 0)
  )
    return;

  // Create Config
  let pageURL = "shows";

  switch (episode?.serviceType) {
    case SEO_PAGE_CATEGORY_ENUM.SHOWS: {
      pageURL = "shows";
      break;
    }
    case SEO_PAGE_CATEGORY_ENUM.KIDS_PROGRAMS: {
      pageURL = "kids/programs";
      break;
    }
    case SEO_PAGE_CATEGORY_ENUM.SPORTS: {
      pageURL = "sports";
      break;
    }
    case SEO_PAGE_CATEGORY_ENUM.NEWS: {
      pageURL = "news";
      break;
    }
    default: {
      pageURL = "shows";
    }
  }

  const {
    tvShowReference: {
      seriesExternalId,
      seriesName,
      seasonNumber,
      episodeNumber,
    },
  } = episode;

  const formattedSeasonNumber = `season-${seasonNumber}`;
  const formattedEpisodeNumber = `episode-${episodeNumber}`;

  const navObj = {
    page: pageURL,
    seriesId: seriesExternalId,
    slug: encodeURI(seriesName || seriesExternalId),
    seasonNumber: formattedSeasonNumber,
    episodeNumber: formattedEpisodeNumber,
    ...extraSlugs,
  };

  return {
    asString: ["", ...Object.values(navObj)].join("/"),
    asObject: { ...navObj },
  };
}
