const isMovie = (media) => media.type == "Movie";
const isSeries = (media) => media.type == "Series";
const isSeason = (media) => media.type == "Season";
const isCharacter = (media) => media.type == "Character";
const isLiveProgram = (media) => media.type == "LiveProgram";
const isMajidSong = (media) =>
  isMovie(media) && media.contentType == "Majid Songs";

const isAShow = (media) => isSeries(media) || isSeason(media);

/**
 * isEpisode
 * this is defined as having the type Movie and contains
 * the object tvShowReference and has attributes/values
 * (not an empty object)
 * @returns boolean
 */
const isEpisode = (media) => {
  const hasTvShowReference =
    "tvShowReference" in media && Object.keys(media.tvShowReference).length > 0;
  return isMovie(media) && hasTvShowReference;
};

const isPodcast = (media) => media.contentType == "Podcast";

const isPodcastEpisode = (media) => {
  return isMovie(media) && media.contentType == "Podcast";
};

const isSport = (media) => {
  return media.channelExternalId?.includes("ad_sports");
};

/**
 * checks the media for attributes that would indicate that
 * this object is a PCW object
 * typescript's data type would've fixed this easy
 * @returns boolean
 */
const isPcwObject = (media) => {
  return (
    "content" in media && "viewDuration" in media && "viewPercentage" in media
  );
};

export default function (media) {
  if (!media) return;

  return {
    isMovie: isMovie(media),
    isSeries: isSeries(media),
    isSeason: isSeason(media),
    isAShow: isAShow(media),
    isPodcast: isPodcast(media),
    isCharacter: isCharacter(media),
    isMajidSong: isMajidSong(media),
    isEpisode: isEpisode(media),
    isPodcastEpisode: isPodcastEpisode(media),
    isSport: isSport(media),
    isPcwObject: isPcwObject(media),
    isLiveProgram: isLiveProgram(media),
  };
}
