import { defineStore } from "pinia";

export const useWatchStore = defineStore("watch", {
  state: () => ({
    channelFetchInfo: {},
    playInfo: null,
    media: null,

    h1Label: null,

    currentEpisode: null,
    // show states
    episodes: null,
    history: null,
    nextEpisode: null,
    nextToWatch: null,
    numberOfEpisodes: null,
    showCache: [],
  }),
  getters: {
    currentSeason() {
      const route = useRoute();

      if (!this.media) return;
      if (useMediaType(this.media).isAShow === false) return;

      const { seasonNumber } = route.params;
      if (!seasonNumber) return;

      return this.media?.tvShowSeasons.find(
        (t) => t.seasonNumber === parseInt(seasonNumber.split("-")?.[1] ?? 1)
      );
    },
  },
  actions: {
    buildH1Label() {
      const { aggregatedVideo, tvShowSeries } = this.media;
      if (aggregatedVideo) {
        this.h1Label = aggregatedVideo.name;
        return;
      }

      if (tvShowSeries) {
        this.h1Label = tvShowSeries.name;
        return;
      }

      this.h1Label = "hello";
    },

    /**
     * onNextEpisode
     * when this button is clicked,
     * extract all relevant values from the currentSeason and the nextEpisode
     * combine them all in a query object
     * and push that to the router.
     *
     * the watcher in watch/index.vue will handle it.
     */
    async onNextEpisode() {
      const route = useRoute();
      const router = useRouter();

      const { fetchPlayingInfo } = useFetchAsset();

      const {
        assetExternalId,
        tvShowReference: { episodeNumber, seasonNumber },
      } = this.nextEpisode;

      this.playInfo = await fetchPlayingInfo(assetExternalId, assetExternalId);

      const parsedPath = useEpisodePath(this.nextEpisode).asObject;

      navigateTo({
        name: route.name,
        params: {
          ...route.params,
          seasonNumber: parsedPath.seasonNumber,
          episodeNumber: parsedPath.episodeNumber,
        },
        replace: true,
        external: true,
      });
    },

    async onEpisodeSelect(season, episode) {
      const route = useRoute();

      const parsedPath = useEpisodePath(episode).asObject;

      navigateTo({
        name: route.name,
        params: {
          ...route.params,
          seasonNumber: parsedPath.seasonNumber,
          episodeNumber: parsedPath.episodeNumber,
        },
        replace: true,
        external: true,
      });
    },
  },
  persist: [
    {
      key: "channelFetchInfo",
      storage: persistedState.sessionStorage,
      paths: ["channelFetchInfo"],
    },
    // {
    //   key: "showCache",
    //   storage: persistedState.sessionStorage,
    //   paths: ["showCache"],
    // },
  ],
});
